import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useAbout = () => {
  const getDefaultLang = () => {
    return localStorage.getItem("i18nextLng") || "en";
  };

  const [data, setData] = useState({});
  const [lang, setLang] = useState(getDefaultLang());

  const fetchData = async (currentLang) => {
    try {
      const { data, status } = await axiosInstance.get(
        `api/about-company?language=${currentLang}`
      );
      if (status === 200) setData(data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (lang) {
      fetchData(lang);
    }
  }, [lang]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentLang = localStorage.getItem("i18nextLng") || "en";
      if (currentLang !== lang) {
        setLang(currentLang);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [lang]);

  return data;
};

export default useAbout;
