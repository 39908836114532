import { Box, Typography } from "@mui/material";
import React from "react";
import Page from "src/components/Page";
import ShoppingLink from "src/pages/user/new-dashboard/components/ShoppingLink";

function Guest() {
  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Box sx={{ textAlign: "center", maxWidth: "800px", mb: 7 }}>
          <Typography variant="h1">Welcome</Typography>
          <Typography variant="">
            Thank you for visiting. As a guest, you have limited access to our
            features. For the full experience, consider creating an account!
            This will unlock more features, allow you to save your preferences,
            and give you access to exclusive content.
          </Typography>
        </Box>
        <ShoppingLink />
      </Box>
    </Page>
  );
}

export default Guest;
