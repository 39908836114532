import React, { useEffect, useState } from "react";
import "./style.css";

import Logo from "./images/syed-logo.png";
import Arrowright from "./images/arrow-right.png";
import AItraing from "./images/power.png";
import useAuth from "src/hooks/useAuth";
import useAbout from "./hooks/use-about";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { Box } from "@mui/material";
import Translate from "src/components/translate";
import { RHFEditor } from "src/components/hook-form";
import ReactQuill from "react-quill";

const Index = () => {
  const { isAuthenticated } = useAuth();
  const data = useAbout();
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <>
      <div className="landingBody">
        <div className="radialbg1"></div>

        <div className="landingContainer">
          <div className="navSection">
            <div className="logoSection">
              <img src={Logo} style={{ width: "40%" }} />
            </div>
            <div className="signupSection">
              <a
                href={isAuthenticated ? "/auth" : "/auth/login"}
                className="login_button">
                {isAuthenticated ? (
                  <Translate> {"global.dashboard"}</Translate>
                ) : (
                  <Translate> {"global.log_in"}</Translate>
                )}
              </a>
              {!isAuthenticated && (
                <a href="/auth/register" className="signup_button">
                  <Translate> {"register.enroll"}</Translate>
                </a>
              )}
              {!isAuthenticated && (
                <a
                  href={"/guest"}
                  className="signup_button"
                  style={{ marginLeft: "5px" }}>
                  <Translate> {"global.guest"}</Translate>
                </a>
              )}
              <Box ml={2}>
                <LanguagePopover />
              </Box>
            </div>
          </div>
        </div>

        <div className="landingContainer section">
          <div className="flex flex-wrap">
            <div className="w_40 pr_15 relative aiPower">
              <div className="radialbg1"></div>
              <img
                src={data?.image}
                className="relative"
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="w_60 pl_85 relative">
              <p className="text-xl coinsNameBox">
                <b>{data?.subject || "Lorem About ???"}</b>
              </p>
              {/* <p className="mt_20 content-justify">
                {data?.content ||
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"}
              </p> */}
              <ReactQuill
                value={data?.content}
                theme="bubble"
                modules={{
                  toolbar: null,
                }}
                readOnly
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
              }}>
              <a
                href="/auth/register"
                target="_self"
                className="banner_button flex items-center mt_20 mb_20">
                <span>
                  <Translate> {"register.enrollNow"}</Translate>
                </span>
                <img className="ml_10" src={Arrowright} />
              </a>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="landingContainer">
            <p className="w_70 mx-auto mt_10 text-center font_13">
              <Translate> {"global.copy"}</Translate> © {currentYear} Syed .
              <Translate> {"global.all_rights"}</Translate>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
