import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShoppingCartIcon from "src/images/shopping-cart.png";
import useIsDarkMode from "src/hooks/use-is-darkmode";
import { useSnackbar } from "notistack";
import Iconify from "src/components/Iconify";
import { useState } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import Transition from "src/utils/dialog-animation";
import axiosInstance from "src/utils/axios";
import Map from "src/components/map";
import Fade from "@mui/material/Fade";
import Translate from "src/components/translate";

function ShoppingLink() {
  const theme = useTheme();
  const isDark = useIsDarkMode();

  const copy = async (url) => {
    await navigator.clipboard.writeText(url);
    enqueueSnackbar("Copied to clipboard");
  };

  const { enqueueSnackbar } = useSnackbar();
  const [openShare, setOpenShare] = useState(false);

  const [data, setData] = useState([]);
  const [selectedLink, setSelectedLink] = useState("");

  const isBetween900And1200 = useMediaQuery(
    "(min-width:900px) and (max-width:1200px)"
  );

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const { status, data } = await axiosInstance.get(`api/amazon-urls`);

        if (status === 200) {
          setData(data.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchLinks();
  }, []);

  return (
    <>
      <Card mt={2} sx={{ p: "20px", m: "20px", maxWidth: "500px" }}>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: isBetween900And1200 ? "250px" : "auto",
            alignItems: "center",
          }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={ShoppingCartIcon} style={{ width: "100px" }} />
          </Box>
          <Box sx={{ textAlign: "center", p: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: isDark ? "white" : "black",
              }}>
              <Translate>user_dashboard.shoppingLink</Translate>
            </Typography>
          </Box>
        </Box>
        <Map
          list={data}
          render={({ title, description, url }) => {
            return (
              <Box
                sx={{
                  mt: "-25px",
                  // borderBottomRightRadius: "4px",
                  // borderBottomLeftRadius: "4px",
                  // borderTopRightRadius: 0,
                  // borderTopLeftRadius: 0,
                  textAlign: "center",
                }}>
                <Box p={2} py={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      padding: "2px 0px",
                      borderRadius: "4px",
                    }}>
                    <Tooltip
                      title={description}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}>
                      <Button
                        variant="outlined"
                        href={url}
                        target="_blank"
                        fullWidth>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            // width: "calc(100% - 70px)",
                          }}>
                          {title}
                        </Box>
                      </Button>
                    </Tooltip>

                    <Box sx={{ width: "70px", display: "flex" }}>
                      <Tooltip
                        title="Copy"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow>
                        <Box
                          sx={{
                            color: isDark ? "primary.light" : "primary.dark",
                            backgroundColor: isDark ? "#fff" : "primary.main",
                            mr: 0.5,
                            ml: 1,
                            borderRadius: "6px",
                            width: "26px",
                            height: "26px",
                            cursor: "pointer",
                            lineHeight: "30px",
                          }}
                          size="small"
                          onClick={() => copy(url)}>
                          <Iconify
                            icon="mi:attachment"
                            sx={{ color: isDark ? "primary.main" : "#fff" }}
                          />
                        </Box>
                      </Tooltip>

                      <Tooltip
                        title="Share"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow>
                        <Box
                          sx={{
                            color: isDark ? "primary.light" : "primary.dark",
                            backgroundColor: isDark ? "#fff" : "primary.main",
                            mr: 0.5,
                            borderRadius: "6px",
                            width: "26px",
                            height: "26px",
                            cursor: "pointer",
                            lineHeight: "30px",
                          }}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setOpenShare(true);
                            setSelectedLink(url);
                          }}>
                          <Iconify
                            icon="mdi:share-outline"
                            sx={{ color: isDark ? "primary.main" : "#fff" }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            );
          }}
        />
      </Card>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openShare}
        onClose={() => setOpenShare(false)}
        TransitionComponent={Transition}>
        <DialogContent
          sx={{
            "& .react-share__ShareButton": {
              margin: 1,
            },
          }}>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              alignItems: "center",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
              },
            }}>
            <Box>
              <Typography variant="subtitle2">Share Shopping Link</Typography>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <IconButton
                sx={{
                  color: isDark ? "warning.light" : "primary.dark",
                  backgroundColor: "#f1f1f19e",
                }}
                variant="contained"
                size="small"
                onClick={() => setOpenShare(false)}>
                <Iconify icon="iconamoon:close-bold" />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TwitterShareButton url={selectedLink}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <FacebookShareButton url={selectedLink}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <TelegramShareButton url={selectedLink}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>

            <WhatsappShareButton url={selectedLink} separator="::">
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShoppingLink;
